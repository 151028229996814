jQuery( document ).ready(function($) {

  // Mobile menu
  $('.menu-trigger').click(function(){
      $(this).toggleClass('active')
      $('.menu-main-menu-container').toggleClass('active')
  })

  $('.menu-item-has-children > a').append( "<i class='fas fa-chevron-down sub-menu-trigger'></i>" );

  $('.menu-item-has-children > a').click(function(e){
    e.preventDefault()
    $(this).next().toggleClass('active')
    $(this).toggleClass('active')
  })


  // posts slider
  $('.slider-posts').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots:false,
    nextArrow: '<button type="button" class="slick-next"> <i class="fas fa-chevron-right"></i> </button>',
    prevArrow: '<button type="button" class="slick-prev"> <i class="fas fa-chevron-left"></i> </button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  });

  // posts slider
  $('.testimonials-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots:true,
    arrows: false,
    nextArrow: '<button type="button" class="slick-next"> <i class="fas fa-chevron-right"></i> </button>',
    prevArrow: '<button type="button" class="slick-prev"> <i class="fas fa-chevron-left"></i> </button>',
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          arrows: true,
          dots: false
        }
      }
    ]
  });

  $('.teacher-trigger').click(function(e){
    e.preventDefault()
    $(this).parent().parent().find('.teacher-popup').toggleClass('active')
  })

  $('.teacher-close').click(function(e){
    e.preventDefault()
    $(this).parent().parent().parent().parent().toggleClass('active')
  })

  $('.read-more-trigger').click(function(e){
    e.preventDefault()
    $(this).prev().toggleClass('all')
    if($(this).text() === 'See more'){
      $(this).html('See less')
    } else{
      $(this).html('See more')
    }
  })

});